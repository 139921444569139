import { Badge, IconButton, Popover } from '@mui/material';
import { styled } from '@mui/system';
import { Info as InfoIcon, Help, Settings as SettingsIcon } from '@mui/icons-material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { PlatformToArchiveMap } from '../../../config/platformSettings';
import colors from '../../../config/theme/colors';
import { actions } from '../../../store/session';
import { isPlatform } from '../../../utility/typeGuards';
import { Container, Item } from '../../Grid';

import logoutIcon from '../images/icon-logout.svg';
import { fetchWithOptions } from '../../../utility/fetch';
import { parseJWTFromCookie } from '../../../utility/jwtAuthTools';
import ToolTip from 'Components/ToolTip';
import { assertCapabilities, useAppSelector } from 'Store/index';
import PlatformCapabilities from '../../../config/capabilities';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import { path } from 'ramda';

const StyledTable = styled('table')({
  margin: '-0.25rem 0.7rem -0.25rem -0.1rem',
  fontSize: '1.8rem',
});

const StyledMenuItem = styled('div')({
  padding: '0.5rem 1rem',
  backgroundColor: colors.white,
  borderTop: `1px solid ${colors.gray}`,
  '&:hover': {
    backgroundColor: colors.lightGray,
  },
  cursor: 'pointer',
});

const NameCircle = styled('div')({
  backgroundColor: colors.white,
  borderRadius: '50rem',
  width: '4.5rem',
  height: '4.5rem',
  color: colors.brandBlue.dark,
  letterSpacing: '0.1rem',
});

const StyledBurgerMenuContainer = styled('div')({
  display: 'flex',
  width: '40px',
  height: '40px',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledBurgerMenuBar = styled('span', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ open }: IStyleProps) => ({
  position: 'relative',
  display: 'block',
  opacity: 1,
  width: '30px',
  height: '3px',
  background: colors.tertiary,
  padding: 0,
  marginBottom: '5px',
  transition: 'opacity 100ms, transform 100ms, position 100ms',
  '&:first-of-type': {
    transform: `rotate(${open ? 45 : 0}deg)`,
    top: `${open ? 8 : 0}px`,
  },
  '&:nth-of-type(2)': {
    opacity: `${open ? 0 : 1}`,
  },
  '&:nth-of-type(3)': {
    marginBottom: 0,
    transform: `rotate(${open ? 135 : 0}deg)`,
    top: `${open ? -8 : 0}px`,
  },
}));

const iconStyle = {
  display: 'block',
  margin: '0 0 0 0.2rem',
  padding: '0',
  fontSize: '2.5rem',
  color: colors.tertiary,
};

interface IStyleProps {
  open?: boolean;
}

/** Single item in user dropdown burger menu. */
const BrgrMenuItem = (props: {
  localizationKey: string;
  icon: JSX.Element;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}): JSX.Element => (
  <StyledMenuItem onClick={props.onClick}>
    <StyledTable>
      <tbody>
        <tr>
          <td style={{ width: '3.5rem' }}>{props.icon}</td>
          <td>
            <FormattedMessage id={props.localizationKey} />
          </td>
        </tr>
      </tbody>
    </StyledTable>
  </StyledMenuItem>
);

const LogoutIcon = styled(({ ...other }) => <img color={'red'} src={logoutIcon} {...other} />)`
  display: block;
  height: 3rem;
  width: 3rem;
`;

interface IMenuContentProps {
  toggleUserMenu: (e: React.MouseEvent<SVGSVGElement | HTMLElement>) => void;
  onClickLogOut: () => void;
  session: ISessionStore;
  setUserSettingsShow: (s: boolean) => () => void;
  setInfoShow: (s: boolean) => () => void;
  orgSettings?: IState['settings']['orgSettings'];
}
class MenuContent extends React.Component<IMenuContentProps> {
  currentPlatform: string | undefined;
  transferablePlatform: boolean | undefined;
  pid: string | undefined;
  session: ISessionStore | undefined;
  props: IMenuContentProps;

  constructor(props: IMenuContentProps) {
    super(props);
    this.transferablePlatform = isPlatform(this.currentPlatform)
      ? PlatformToArchiveMap[this.currentPlatform].length > 0
      : false;
    this.pid = props.session.data?.patientid;
    this.props = props;
  }

  render() {
    return (
      <>
        <BrgrMenuItem
          localizationKey="header.userSettings.settings"
          icon={
            <SettingsIcon
              style={{
                display: 'block',
                margin: '0 0 0 0.2rem',
                padding: '0',
                fontSize: '2.5rem',
                color: colors.tertiary,
              }}
            />
          }
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            this.props.toggleUserMenu(e);
            this.props.setUserSettingsShow(true)();
          }}
        />

        <BrgrMenuItem
          localizationKey="header.softwareInstructions"
          icon={<Help style={iconStyle} />}
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            fetchWithOptions(`/api/read/fs/softwareinstruction`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
              .then(
                (res: Response) => {
                  if (res.status === 200) {
                    return res.blob();
                  } else {
                    throw { status: res.status, fullResponse: res };
                  }
                },
                (error: Error) => {
                  throw error;
                },
              )
              .then((res) => {
                const objectURL = URL.createObjectURL(res);
                window.open(objectURL, '_blank');
              })
              .catch(() => {
                return;
              });
            this.props.toggleUserMenu(e);
          }}
        />
        <BrgrMenuItem
          localizationKey="header.about"
          icon={<InfoIcon style={iconStyle} />}
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            this.props.setInfoShow(true)();
            this.props.toggleUserMenu(e);
          }}
        />
        <BrgrMenuItem
          localizationKey="header.logout"
          icon={<LogoutIcon />}
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            this.props.onClickLogOut();
            this.props.toggleUserMenu(e);
          }}
        />
      </>
    );
  }
}

type TProps = IBurgerUsericon & IDispatchFromProps & IStateFromProps;

const makeBoxStyle = (x: number, y: number, visibility: boolean, boxIcon: boolean) =>
  `width: 29px; height: 29px; position: absolute; border: 3px solid ${
    colors.warning.light
  }; border-radius: 5px; display: ${visibility ? 'block' : 'none'}; top: ${y - (boxIcon ? 1 : 6)}px; left: ${
    x - (boxIcon ? 1 : 6)
  }px;`;

/**
 * Helper element that renders a bordered box over an (icon) element
 */
const TemporaryIconMovedHelper = ({ capabilityGroups = {} }: ICapabilityContextProps) => {
  /** Insert an overlay element under the root element of the website */
  const mainElement = document.getElementById('root');
  const helperOverlayElement = document.createElement('div');
  helperOverlayElement.setAttribute('id', 'helperOverlayElement');
  helperOverlayElement.setAttribute('style', 'position: absolute; top: 0px; left: 0px');

  React.useEffect(() => {
    mainElement?.appendChild(helperOverlayElement);
    return () => {
      helperOverlayElement?.remove();
    };
  }, []);

  /** Create a div that renders a box */
  const boxElement = document.createElement('div');
  boxElement.setAttribute('id', 'helperBoxElement');
  boxElement.setAttribute('style', 'display: none');
  helperOverlayElement.appendChild(boxElement);

  const hoverFunction = (element: string) => {
    let censorElement: HTMLElement | null = null;
    switch (element) {
      case 'censor': {
        censorElement = document.getElementById('informationCensorIcon');
        break;
      }
      case 'patientRecords': {
        censorElement = document.getElementById('patientRecordsIcon');
        break;
      }
      case 'stats': {
        censorElement = document.getElementById('statsIcon');
        break;
      }
      case 'patientSearch': {
        censorElement = document.getElementById('searchIcon');
        break;
      }
      case 'support': {
        censorElement = document.getElementById('supportIcon');
        break;
      }
      default:
        break;
    }

    const x = censorElement?.getBoundingClientRect().x;
    const y = censorElement?.getBoundingClientRect().y;
    if (x && y) {
      // Render and place box over the icon
      boxElement.setAttribute(
        'style',
        makeBoxStyle(x, y, true, element === 'stats' || element === 'patientSearch' || element === 'support'),
      );
    }
  };

  const hoverOut = () => {
    boxElement.setAttribute('style', 'display: none');
  };

  const session = useAppSelector((s) => s.session);
  const clinicalDialogCapability = assertCapabilities([PlatformCapabilities.CLINICAL_RECORDS], capabilityGroups);
  const currentPlatform = session?.platforms?.selected;
  const transferablePlatform = isPlatform(currentPlatform) ? PlatformToArchiveMap[currentPlatform].length > 0 : false;
  const orgSettings = useAppSelector((s) => s.settings.orgSettings);
  const powerbiReportId = path(['settings', 'powerbi', currentPlatform || '', 'reportId'], orgSettings);

  return (
    <div style={{ padding: '0rem 1rem' }}>
      <h3 style={{ width: '20rem' }}>
        <FormattedMessage id="header.menuHelperText.iconsMovedText" />
      </h3>
      <ul style={{ marginTop: '-1rem' }}>
        {clinicalDialogCapability && transferablePlatform ? (
          <li style={{ cursor: 'pointer' }} onMouseOver={() => hoverFunction('patientRecords')} onMouseOut={hoverOut}>
            <FormattedMessage id="header.menuHelperText.patientRecord" />
          </li>
        ) : null}
        {session?.data?.visitid && currentPlatform ? (
          <li style={{ cursor: 'pointer' }} onMouseOver={() => hoverFunction('censor')} onMouseOut={hoverOut}>
            <FormattedMessage id="header.menuHelperText.hidingInfo" />
          </li>
        ) : null}
        {powerbiReportId ? (
          <li style={{ cursor: 'pointer' }} onMouseOver={() => hoverFunction('stats')} onMouseOut={hoverOut}>
            <FormattedMessage id="header.menuHelperText.stats" />
          </li>
        ) : null}
        {session?.data?.visitid && currentPlatform ? (
          <li style={{ cursor: 'pointer' }} onMouseOver={() => hoverFunction('patientSearch')} onMouseOut={hoverOut}>
            <FormattedMessage id="header.menuHelperText.patientSearch" />
          </li>
        ) : null}
        <li style={{ cursor: 'pointer' }} onMouseOver={() => hoverFunction('support')} onMouseOut={hoverOut}>
          <FormattedMessage id="header.menuHelperText.support" />
        </li>
      </ul>
    </div>
  );
};

const TemporaryIconMovedHelperC = withCapabilities(TemporaryIconMovedHelper);

class BurgerUsericon extends React.Component<TProps> {
  userInfoMenuRef: React.RefObject<HTMLDivElement>;

  constructor(props: TProps) {
    super(props);
    this.userInfoMenuRef = React.createRef();
    this.state = { users: null };
  }

  onClickLogOut = (): void => {
    this.props.logOut && this.props.logOut();
  };

  render(): JSX.Element {
    const { session, showMenu, setUserSettingsShow, setInfoShow, toggleUserMenu, concurrentUsers } = this.props;

    const otherUsers = concurrentUsers
      .filter((s) => s.useruuid !== session.data?.useruuid)
      .map((user) => {
        const users = this.props.session.orgUsers || [];
        const vaultUser = users.find((u) => u.userId === user.useruuid);

        if (!vaultUser && user.orgid === this.props.session.data?.orgid)
          return { name: 'thisOrgUser', id: user.useruuid };
        if (!vaultUser) return { name: 'otherOrgUser', id: user.useruuid };

        return { name: `${vaultUser?.lastNames} ${vaultUser?.firstNames}`, id: user.useruuid };
      });

    return (
      <Container style={{ height: '100%', color: colors.tertiary }} alignItems="center" justifyContent="flex-end">
        <Item>
          <IconButton
            id="hamburger"
            color="inherit"
            disableRipple
            style={{
              cursor: 'pointer',
              padding: 0,
              zIndex: showMenu ? 2000 : undefined,
            }}
            onClick={toggleUserMenu}
          >
            <StyledBurgerMenuContainer>
              <div>
                <StyledBurgerMenuBar open={!!showMenu} />
                <StyledBurgerMenuBar open={!!showMenu} />
                <StyledBurgerMenuBar open={!!showMenu} />
              </div>
            </StyledBurgerMenuContainer>
          </IconButton>
          <Popover
            open={!!showMenu}
            anchorEl={showMenu}
            onClose={toggleUserMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{ elevation: 3, style: { borderRadius: '0.3rem' } }}
            //MenuListProps={{ style: { padding: 0 } }}
            ref={this.userInfoMenuRef}
          >
            <MenuContent
              toggleUserMenu={toggleUserMenu}
              onClickLogOut={this.onClickLogOut.bind(this)}
              session={session}
              setUserSettingsShow={setUserSettingsShow}
              setInfoShow={setInfoShow}
              orgSettings={this.props.orgSettings}
            />
            <TemporaryIconMovedHelperC />
          </Popover>
        </Item>
        <Item style={{ paddingLeft: '2rem' }}>
          <Badge
            badgeContent={
              otherUsers.length > 0 ? (
                <ToolTip
                  content={'+' + otherUsers.length}
                  title={
                    <>
                      <FormattedMessage id="header.userLookingAtSamePatient" />
                      <ul>
                        {otherUsers.map((u) => (
                          <li key={u.id}>
                            {u.name === 'thisOrgUser' ? (
                              <FormattedMessage id="header.thisOrgUser" />
                            ) : u.name === 'otherOrgUser' ? (
                              <FormattedMessage id="header.otherOrgUser" />
                            ) : (
                              u.name
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  }
                  hover
                ></ToolTip>
              ) : undefined
            }
            color={'primary'}
            slotProps={{ badge: { style: { fontSize: '1.6rem', fontWeight: 600 } } }}
          >
            <NameCircle>
              <Container alignItems={'center'} justifyContent={'center'} style={{ height: '100%' }}>
                <Item>
                  <h2 style={{ margin: 0, padding: 0 }}>
                    {session.data?.userfirstnames.charAt(0)}
                    {session.data?.userlastnames.charAt(0)}
                  </h2>
                </Item>
              </Container>
            </NameCircle>
          </Badge>
        </Item>
      </Container>
    );
  }
}

interface IDispatchFromProps {
  logOut?: () => void;
}

const mapDispatchToProps = (dispatch: any): IDispatchFromProps => ({
  logOut: (): void => dispatch(actions.logOut()),
});

interface IStateFromProps {
  orgSettings?: IState['settings']['orgSettings'];
  concurrentUsers: Array<{ orgid: string; useruuid: string; patientid: string; jti: string }>;
}

const mapStateToProps = (state: IState): IStateFromProps => ({
  orgSettings: state.settings.orgSettings,
  concurrentUsers: state.session.websocket.subscribers,
});

interface IBurgerUsericon {
  session: ISessionStore;
  dispatch: any;
  toggleUserMenu: (e: React.MouseEvent<SVGSVGElement | HTMLElement>) => void;
  showMenu: SVGSVGElement | HTMLElement | null;
  setUserSettingsShow: (s: boolean) => () => void;
  setInfoShow: (s: boolean) => () => void;
}

export default connect(mapStateToProps, mapDispatchToProps)(BurgerUsericon);
